import { useState, useEffect, useMemo, useContext } from 'react';
import moment from 'moment';
import 'ui/theme/find.css';
import './style.css';
import PreferencesIcon from '@atlaskit/icon/glyph/preferences';
import InfoIcon from '@atlaskit/icon/glyph/info';

import * as Administration from 'modules/administration';
import * as Devices from 'modules/devices';

import { HealthcareSite, Organisation } from 'services/server/functions/model/administration/model';
import QuickFilters from 'ui/components/QuickFilters';

import Back from 'ui/components/Back';
import DetailsBlock from 'ui/components/DetailsBlock';
import { Study } from 'services/server/functions/model/diagnosis/model';
import StudyStatusLegend from '../components/StudyStatusLegend';
import Table from 'ui/components/Table';
import { Test } from 'services/server/functions/model/diagnosis/model/Test';
import {Text} from 'ui/components/Text';
import useCurrentUser from 'ui/hooks/useCurrentUser';
import useLocationParams from 'ui/hooks/useLocationParams';
import useSnapshot from 'ui/hooks/useSnapshot';
import { Checkbox } from 'features/components/Checkbox';
import { StudyFilters } from 'features/diagnosis/components/StudyFilters';
import { StudyFilterActions } from 'features/diagnosis/components/StudyFilters/components/actions';
import { Reports, ReportConfigTypes, getTableReportConfig, getTableReportData } from 'features/reports/helpers/tableReportConfig';
import { AccordionLinkButton } from 'features/components/Accordion';
import { expandStudies, getStudyTestIds, prepareTests } from "services/server/functions/model/diagnosis/connector/study-report.connector";
import Spinner from '@atlaskit/spinner';
import { User } from 'services/server/functions/model/authentication/model';
import { useCounter } from 'ui/hooks/useCounter';
import { getMaskCombinations } from 'services/server/functions/helpers/mask';
import { Report } from 'services/server/functions/model/diagnosis/model/Report';
import { UserContext } from 'features/providers/userContextProvider';
import { applyAnds, applyOrs } from 'features/helpers/filtersHelper';
import { FiltersType } from 'features/diagnosis/components/StudyFilters/utils';

const SEVERITY_GROUPS_MASK_ORDER = Object.keys(Report.SEVERITIES_GROUPS).sort();
const TEST_STATUS_GROUPS_MASK_ORDER = Object.keys(Study.entities.Test.STATUS_GROUP).filter(key => typeof Study.entities.Test.STATUS_GROUP[key] === 'string').sort();

const sortByTests = (_1, _2, order, _3, row1, row2) => {
  const diffCompletedTest = Object.values(row1.tests).filter(t => Test.STATUS.compare(t.status, Test.STATUS.analysed) >= 0).length - Object.values(row2.tests).filter(t => Test.STATUS.compare(t.status, Test.STATUS.analysed) >= 0).length;
  const diffRequestedTest = row1.requestedTests - row2.requestedTests;
  const diffStatus = Study.STATUS.compare(row1.status, row2.status);
  
  let result = (order === 'asc' ? 1 : -1) * (diffCompletedTest > 0 ? 1 : diffCompletedTest < 0 ? -1 : 0);
  if (result === 0) result = (order === 'asc' ? 1 : -1) * (diffRequestedTest > 0 ? 1 : diffRequestedTest < 0  ? -1 : 0);
  if (result === 0) result = (order === 'asc' ? 1 : -1) * (diffStatus > 0 ? 1 : diffStatus < 0 ? -1 : 0);
  return result;
}

const FindStudyUI = ({ loading, loadingCounters, loadingArchived, loadingExportData, showAdvanceFilters, setShowAdvanceFilters, statuses, filters, setFilters, maxNumberOfTests, statusCounts, organisation, healthcaresite, trial, device, patientID, patientBirth, clinician, clinicianOptions, showArchived, exclusiveArchived, amountOfArchivedStudies, onStatusChangedHandler, onShowArchivedChangedHandler, currentUser, notify, organisations, healthcaresites, tableConfig, productId, batchId, reportType = Reports.StudyReport.key }) => {
  
  const exportProps = { statuses, productId, filters, organisation, healthcaresite, trial, device, patientID, patientBirth, clinician, showArchived, exclusiveArchived, notify, reportType, disabled: !tableConfig.data.length, expandedStudies: tableConfig.expandedStudies, batchId };
  
  const report = Object.values(Reports).find(r => r.key === reportType);
  const isSummaryReport = reportType === Reports.ActivitySummary.key;
  
  return (
    <div id='FindStudy' className='page'>
      <Back />
      <div className='pageHeader'>
        <h1 className='title'><Text>{report.label}</Text></h1>
        <StudyFilterActions {...exportProps} />
      </div>
      <div className='pageContent'>
        <div className="pageSection">
          <div className="find-study-advance-search">
            <div className='title'><Text>search-by</Text></div>
            {!isSummaryReport && (
              <AccordionLinkButton label={<Text>global.filters.advance_search.label</Text>} handleClick={() => setShowAdvanceFilters(!showAdvanceFilters)} >
                <PreferencesIcon size="smal" />
              </AccordionLinkButton>
            )}
          </div>
          <DetailsBlock>
            <StudyFilters
              batchIds={[...tableConfig.expandedStudies.reduce((acc, curr) => { if (curr.metadata.batchId) acc.add(curr.metadata.batchId); return acc;}, new Set())]}
              currentUser={currentUser}
              snapshots={{ organisations, healthcaresites, clinicianOptions }}
              filters={filters}
              maxNumberOfTests={maxNumberOfTests}
              onFilterChange={setFilters}
              reportType={reportType}
              showAdvanceFilters={showAdvanceFilters}>
              {!isSummaryReport && (
                <div className='filter grid-row' >
                  {/*TODO: Study.schema.patienID can be used to validate for this field*/}
                  <div className="filters-block">
                    <QuickFilters legend={<StudyStatusLegend filter={s => Object.keys(statusCounts).includes(s)} id="statusHelp" place="right" />} header={Text.resolve("status").concat(":")} >
                      <QuickFilters.Multi>
                        {Object.keys(statusCounts)
                          .sort(Study.STATUS_GROUP.compare)
                          .map((g, idx) => <QuickFilters.Item key={'STUDY_STATUS' + idx} id={g.charAt(0).concat(g.slice(1).toLowerCase()).replace(" ", "")} className={g.concat(" expanded")} active={statuses.includes(g)} onSelected={() => (!statuses.includes(g) && onStatusChangedHandler([...statuses, g]))} onUnSelected={() => onStatusChangedHandler(statuses.filter(s => s !== g))}>{Text.resolve(g, {}, { schema: "diagnosis.Study" })} ({loadingCounters ? <Spinner size="small"/> : statusCounts[g]})</QuickFilters.Item>
                          )}
                      </QuickFilters.Multi>
                      <QuickFilters.Select label='' className="selected collapsed" onChange={(e) => statuses.includes(e.target.value) ? onStatusChangedHandler(statuses.filter(s => s !== e.target.value)) : onStatusChangedHandler([...statuses, e.target.value])} options={Object.keys(statusCounts).sort(Study.STATUS_GROUP.compare).map(key => { return { label: Text.resolve(key, {}, { schema: "diagnosis.Study" }).toUpperCase(), value: key } }).concat({ label: Text.resolve('any').toUpperCase() })} />
                    </QuickFilters>
                    <div style={{ display: "flex", gap: "5px", alignItems: 'center' }}>
                      <Checkbox
                        label={reportType === Reports.StudyReport.key ? Text.resolve('module.diagnosis.fe.view.findStudy.archive_filter') : Text.resolve('module.diagnosis.fe.view.findStudy.include_archive_filter')}
                        selected={showArchived}
                        disabled={loadingArchived || (!amountOfArchivedStudies && !showArchived)}
                        onChange={(status) => onShowArchivedChangedHandler(status)}
                      />
                      <span>({loadingArchived ? <Spinner size="small"/> : amountOfArchivedStudies})</span>
                    </div>
                  </div>
                  {reportType === Reports.StudyReport.key && showArchived === false && <div className="filters-block">
                    <div className="grid-col fitContent"><div className="info"><InfoIcon /></div><span><Text>module.diagnosis.fe.view.findStudy.info_non_archived</Text></span></div>
                  </div>}
                  <QuickFilters header="Filters:">
                    {trial && <Administration.ViewTrial.AsLinkIcon {...{ trial, className: "quickfilter selected" }} />}
                    {device && <Devices.ViewDevice.AsLinkIcon {...{ device, className: "quickfilter selected" }} />}
                  </QuickFilters>
                </div>
              )}
            </StudyFilters>
          </DetailsBlock>
        </div>
        <div className="pageSection">
          <Table
            {...tableConfig}
            loading={loading || loadingExportData}
            />
        </div>
      </div>
    </div>
  );
};

const onError = (notify, callback) => reason => {
  notify?.('error', Text.resolve('App.loading-data-failed'), Text.resolve('App.loading-data-failed-resolution'));
  callback?.(reason);
  console.error("FindStudy useSnapshot Error", reason);
};

const FindStudyPresenter = (props) => {
  const fieldMapping = tableField => tableField === 'lastUpdate' ? 'metadata.lastEvent' : tableField;

  const [currentUser] = useCurrentUser();
  const { selectedProduct } = useContext(UserContext);
  const productId = selectedProduct?.id;
  const [originalUser] = useCurrentUser({ skipContext: true });
  const [locState, setLocationState] = useLocationParams({
    patientID: undefined,
    organisation: undefined,
    healthcaresite: undefined,
    trial: undefined,
    statuses: undefined,
    device: undefined,
    clinician: undefined,
    patientBirth: undefined,
    showArchived: undefined,
    reportType: undefined,
    testStatus: undefined,
    batchId: undefined,
    requestedTests: undefined,
    dateCreated: undefined,
    dateConducted: undefined,
    lastUpdated: undefined,
    sortField: undefined,
    sortOrder: undefined,
    showAdvanceFilters: undefined,
    visited: undefined,
    highlightedRow: undefined,
  }, { coerce: { requestedTests: (val) => val === null ? undefined : Number(val), showArchived: Boolean }}); // Necessary to coerce requestedTests like this, otherwirse it defaults to 0

  const setShowAdvanceFilters = (val) => { setLocationState({ showAdvanceFilters: val }); return val; };

  const {
    patientID: pID,
    organisation: org,
    healthcaresite: hcs,
    trial,
    statuses = [],
    device: dev,
    clinician: locCli,
    patientBirth,
    reportType = ReportConfigTypes.StudyReport,
    showArchived = props.showArchived || reportType === ReportConfigTypes.StudyReport ? false : undefined,
    requestedTests,
    testStatus,
    batchId,
    severity,
    dateCreated,
    dateConducted,
    lastUpdated,
    filters: locFilters,
    sortField = fieldMapping('lastUpdate'),
    sortOrder = 'asc',
    showAdvanceFilters = setShowAdvanceFilters(Boolean(org || hcs || lastUpdated)),
    visited = [],
    highlightedRow,
  } = locState;

  const setVisited = (newVisited) => {
    if (!newVisited.length) visited.forEach(() => visited.pop());
    setLocationState({ visited: newVisited });
  };

  const setHighlightedRow = (rowId) => {
    setLocationState({ highlightedRow: rowId });
  };

  const [tableSettings, setTableSettings] = useState({ sizePerPage: 10 });
  const setSomeTableSettings = (obj) => setTableSettings(prev => ({ ...prev, ...obj }));
  const setSort = (sortField, sortOrder) => { setLocationState({sortField, sortOrder}); };

  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedActionResolution, setSelectedActionResolution] = useState({});
  const setSelectedId = (id, isChecked) => setSelectedIds((prevSelectedIds) => isChecked ? [...new Set([...prevSelectedIds, id])] : prevSelectedIds.filter(i => id !== i));
  
  /// withStudies

  const owner = hcs || org;

  const studyRef = pID?.startsWith('R-') ? pID.replace(/[^\d]/g, '') : undefined;
  const patientRef = pID?.startsWith('P-') ? pID : undefined;
  
  const filterField = studyRef ? 'activationCode.value' : (patientRef ? 'patient.reference' : undefined);
  const filterValue = studyRef || patientRef;

  const listenToChanges = !showArchived;
  const isStudyReport = reportType === ReportConfigTypes.StudyReport;
  const dateRange    = lastUpdated?.split('/'); // This firestore constraint workaround (multiple fields with range operator not allowed) should be moved to database.js

  const dateCreatedFilters = dateCreated?.split("/")?.length === 2 ? [
    ['date', '>=', moment(dateCreated.split('/')[0]).format('YYYY-MM-DDTHH:mm:ss.SSSZ')],
    ['date', '<', moment(dateCreated.split('/')[1]).format('YYYY-MM-DDTHH:mm:ss.SSSZ')],
  ] : [];
  const dateConductedFilters = dateConducted?.split("/")?.length === 2 ? [
    ['metadata.conductedDate', '>=', moment(dateConducted.split('/')[0]).format('YYYY-MM-DDTHH:mm:ss.SSSZ')],
    ['metadata.conductedDate', '<', moment(dateConducted.split('/')[1]).format('YYYY-MM-DDTHH:mm:ss.SSSZ')],
  ] : [];

  const testSeverityMask = useMemo(() => severity ? getMaskCombinations(SEVERITY_GROUPS_MASK_ORDER, [severity]) : [], [severity]);
  const testStatusMask = useMemo(() => testStatus && testStatus !== "REPEATED" ? getMaskCombinations(TEST_STATUS_GROUPS_MASK_ORDER, [testStatus]) : [], [testStatus]);
  const filterByRepeated = testStatus === "REPEATED";

  const mainAndFilters = [
    ['clinician', '==', locCli],
    ['patient.birthDate', '==', patientBirth ? moment(patientBirth).format('DD/MM/YYYY') : undefined],
    ...dateCreatedFilters,
    ...dateConductedFilters,
    ['requestedTests', '==', requestedTests],
    [`metadata.testsSeverityGroupMask`, 'in', testSeverityMask.length ? testSeverityMask : undefined],
    [`metadata.testsStatusGroupMask`, 'in', testStatusMask.length ? testStatusMask : undefined], // TODO: maximum of 30 disjunctions!! + 10 limit
    [`metadata.hasRepeatedTest`, '==', filterByRepeated ? filterByRepeated : undefined],
    [`metadata.batchId`, 'starts-with', batchId?.length ? batchId : undefined],
  ].filter(Boolean).filter(([_f,_o,v]) => v !== undefined && v !== null);

  const andStatusFilters = applyAnds()(mainAndFilters);

  const mainOrFilters = [  
    [[filterField, 'starts-with', filterValue]],
    [['patient.id', 'starts-with', pID?.length ? pID : undefined]],
  ].filter(Boolean).filter(([[_f,_o,v]]) => v !== undefined && v !== null);
  const fullStatusFilters = applyOrs(andStatusFilters)(mainOrFilters);
  const applyOrsToFullStatus = applyOrs(fullStatusFilters);
  const applyFilterByStatuses = (list) => { list = Array.isArray(list) ? list : [list]; return applyOrsToFullStatus(list.map(s => [['status', '==', s]])); };

  const [ CREATED=0,     loadingCreated, reloadCreated ]    = useCounter(Study, { owner, filters: applyFilterByStatuses('CREATED'), showArchived, dateRange, withProduct: true });
  const [ IN_PROGRESS=0, loadingInProgress, reloadInProgress ] = useCounter(Study, { owner, filters: applyFilterByStatuses('IN_PROGRESS'), showArchived, dateRange, withProduct: true });
  const [ FINISHED=0,    loadingFinished, reloadFinished ]   = useCounter(Study, { owner, filters: applyFilterByStatuses('FINISHED'), showArchived, dateRange, withProduct: true });
  const [ REVIEWED=0,    loadingReviewed, reloadReviewed ]   = useCounter(Study, { owner, filters: applyFilterByStatuses('REVIEWED'), showArchived, dateRange, withProduct: true });
  const [ ARCHIVED=0,    loadingArchived, reloadArchived ]   = useCounter(Study, { owner, showArchived: true, filters: applyFilterByStatuses(statuses), dateRange, withProduct: true });
  const loadingCounters = loadingCreated || loadingInProgress || loadingFinished || loadingReviewed;
  const reloadCounters = () => { [reloadCreated, reloadInProgress, reloadFinished, reloadReviewed, reloadArchived].forEach(fn => fn()) };
  const studiesByStatus = {
    CREATED, IN_PROGRESS, FINISHED, REVIEWED,
  };

  const studySnapshotsExtraAndFilters = [
    ['metadata.allOwners', 'array-contains', owner],
  ].filter(Boolean).filter(([_f,_o,v]) => v !== undefined && v !== null);
  const studySnapshotsMainExtraFilters = applyAnds(fullStatusFilters)(studySnapshotsExtraAndFilters);
  
  const studySnapshotsExtraOrFilters = [
    ...statuses.map(s => [['status', '==', s]]),
  ].filter(Boolean).filter(([[_f,_o,v]]) => v !== undefined && v !== null);
  
  const studyMainFilters = applyOrs(studySnapshotsMainExtraFilters)(studySnapshotsExtraOrFilters);

  const [snapshots={}, loadingStudies, loadNextPage, loadPrevPage, _reloadData] = useSnapshot(Study, {
    pageSize: isStudyReport ? tableSettings.sizePerPage : undefined,
    visited,
    setVisited,
    listenToChanges: false,
    disableCache: true,
    showArchived, 
    dateRange,
    withProduct: true,
    filters: studyMainFilters,
    sort: isStudyReport ? {field: sortField, order: sortOrder} : undefined,
    onError: onError(props.notify) 
  });
  const reloadData = () => {
    setVisited([]);
    return _reloadData();
  }
  
  const [filtersDataLoaded, setFiltersDataLoaded] = useState(false);

  const report = reportType ? Object.values(require('features/reports/helpers/tableReportConfig').Reports).find(r => r.key === reportType) : false;
  const hasAccessToReports = currentUser.hasAccess(report.query);
  
  let allSnapshots = Object.values(snapshots).filter(Boolean);
  let testAutoStart = false;
  let loadingTests = testAutoStart && hasAccessToReports;
  let testsDateRange, tids, total;
  if (hasAccessToReports) {
    total = Object.keys(snapshots).length;
    testAutoStart = filtersDataLoaded && Boolean(total) && !loadingStudies;
    if (total > 100) {
      tids = undefined;
      const ts = Object.values(snapshots).map(s => s.metadata?.events).filter(Boolean).reduce((ts, es) => ({ min: Math.min(es.at(0).timestamp, ts.min), max: Math.max(es.at(-1).timestamp, ts.min) }), {min: Number.MAX_VALUE, max: Number.MIN_VALUE});
      testsDateRange = [new Date(ts.min).toString(), new Date(ts.max).toString()];
      testAutoStart = testAutoStart && testsDateRange !== undefined;
    } else {
      tids = Object.values(snapshots).map(getStudyTestIds).reduce((a, ids) => [...a, ...ids] ,[])
      testAutoStart = testAutoStart && tids.length;
    }
  }

  // Hooks CANNOT be inside if statements!
  const [testSnapshots={}, _loadingTestSnapshots, _1, _2, reloadTests] = useSnapshot(Study.entities.Test, tids, { autoStart: testAutoStart, listenToChanges, disableCache: true, showArchived, dateRange: testsDateRange }) // { onError: onError(props.notify, other?.onError) });

  if (hasAccessToReports) {
    loadingTests = testAutoStart && (!filtersDataLoaded || loadingStudies || (total > 0 && Object.values(testSnapshots).length === 0) || _loadingTestSnapshots);

    allSnapshots = allSnapshots.map(snapshot => ({
        ...snapshot,
        data: {
          ...snapshot.data,
          get tests() {
            delete this.tests;
            const testIds = getStudyTestIds(snapshot);
            const testEntities = testIds?.map(id => testSnapshots?.[id]).filter(Boolean).reduce((all, test) => {all[test.aggregate.id] = test; return all}, {});
            this.tests = prepareTests({ study: snapshot, testIds, testEntities });
            return this.tests;
          }
        }
      })
    );
  }

  /// end withStudies
  
  const [doB, setDoB] = useState();

  useEffect(() => {
    const nullDateValues = [undefined, null, "null", ""];
    let date;
    if (nullDateValues.includes(patientBirth)) date = null;
    else date = new Date(patientBirth);
    setDoB(date);
  }, [patientBirth]);

  const isAdmin = currentUser.isAdmin();
  const isManager = originalUser.hasPermission(Study.roles.manager.id);

  const filteredStudies = allSnapshots;
  const useLocCli = [doB, pID, org, hcs, statuses, dev, lastUpdated, locCli].some(f => f !== undefined);
  const cli = useLocCli ? locCli : ((!isAdmin && isManager && filteredStudies.some(s => s.data.clinician === currentUser.data?.id)) ? currentUser.data?.id : undefined);
  if (!locCli && cli) setLocationState({ clinician: cli });

  const filters = {
    patientID: pID,
    requestedTests,
    // ugly hack to show testStatus to COMPLETED when severity is set without actually using the filter as they are incompatible (UI requirement)
    testStatus: severity ? Test.STATUS_GROUP.COMPLETED : testStatus,
    severity,
    dateCreated: dateCreated ?? locFilters?.dateCreated,
    dateConducted: dateConducted ?? locFilters?.dateConducted,
    patientBirth, 
    lastUpdated,
    organisation: org,
    healthcaresite: hcs,
    clinician: cli,
    batchId,
  };

  const statusCounts = studiesByStatus;

  const startUsersLoading = !loadingStudies;
  const [_users={}, loadingUser]           = useSnapshot(User, { owner, listenToChanges: false, autoStart: startUsersLoading });
  const clinicianOptions = _users?.data ?           [{ ..._users.data, allOwners: _users.metadata.allOwners }]           : Object.values(_users).filter(Boolean).map(s => ({ ...s.data, allOwners: s.metadata.allOwners })) || [];
  const startOrgsLoading = !loadingUser;
  const [_organisations={}, loadingOrgs]   = useSnapshot(Organisation, { listenToChanges: false, autoStart: startOrgsLoading });
  const organisations    = _organisations?.data ?   [_organisations.data]   : Object.values(_organisations).filter(Boolean).map(s => s.data) || [];
  const startHCSLoading = startOrgsLoading && !loadingOrgs;
  const [_healthcaresites={}, loadingHCSs] = useSnapshot(HealthcareSite, { owner: org, listenToChanges: false, autoStart: startHCSLoading });
  const healthcaresites  = _healthcaresites?.data ? [_healthcaresites.data] : Object.values(_healthcaresites).filter(Boolean).map(s => s.data) || [];

  const loadingFiltersData = loadingStudies || !startUsersLoading || loadingUser || !startOrgsLoading || loadingOrgs || !startHCSLoading || !Object.keys(_healthcaresites).length || loadingHCSs;
  const loadingExportData  = loadingStudies || loadingFiltersData || loadingTests;

  useEffect(_ => {
    if (!loadingFiltersData) !filtersDataLoaded && setFiltersDataLoaded(true);
  }, [loadingFiltersData, filtersDataLoaded]);

  const expandedStudies = expandStudies(filteredStudies, { organisations, healthcareSites: healthcaresites, clinicians: clinicianOptions });
  const tableConfig = getTableReportConfig(reportType)({
    sortFunc: sortByTests,
    expandedStudies,
    filters,
    selectedIds,
    setSelectedId,
    setSelectedIds,
    selectedActionResolution,
    setSelectedActionResolution,
    cancelStudyBatch: props.cancelStudyBatch,
    reloadData: () => { [reloadCounters, reloadData, reloadTests].forEach(fn => fn()) },
    highlightedRow,
    setHighlightedRow,
    get data () { return getTableReportData(reportType)(expandedStudies); }
  });

  const onSizePerPageChange = (sizePerPage) => {
    if (sizePerPage !== tableSettings.sizePerPage) {
      reloadData();
      setSomeTableSettings({ sizePerPage });
    }
  };
  const getTotalSize = () => {
    if (!isStudyReport) return undefined;
    if (showArchived) {
      if (loadingArchived || ARCHIVED === 0) return 0;
      return ARCHIVED;
    }
    const total = Object.keys(studiesByStatus).filter(s => statuses.length ? statuses.includes(s) : true).reduce((acc, curr) => acc + studiesByStatus[curr], 0);
    if (loadingCounters || total === 0) return 0;
    return total;
  };
  Object.assign(tableConfig, {
    ...tableSettings,
    onSortChange: isStudyReport ? (field, order) => { (sortOrder !== order || sortField !== fieldMapping(field)) && setSort(fieldMapping(field), order); } : undefined,
    onSizePerPageChange,
    onPageChange: isStudyReport ? (pageBtn => pageBtn === "<<" ? reloadData() : pageBtn === "<" ? loadPrevPage() : loadNextPage()) : undefined,
    showTotal: isStudyReport ? true : undefined,
    totalSize: getTotalSize(),
    pageNumber: visited.length + 1,
  });

  // UGLY but those are requirements! by @emilio show everything except REVIEWED when they have 0 count
  if (statusCounts[Study.STATUS_GROUP.REVIEWED] === 0) delete statusCounts[Study.STATUS_GROUP.REVIEWED];

  const onChange = state => {
    /*
    and:
       healthcaresite || organisation, patientBirth, clinician requestedTests, testStatus, severity 
       exclusive: (patientID, dateCreated, dateConducted, lastUpdated) || (severity, testStatus)
    rules:
       1. when (healthcaresite || organisation) changes, unset 'clinician'
       2. when oneof (patientID, dateCreated, dateConducted, lastUpdated) || (severity, testStatus) changes, unset the others
    */
    if (state.healthcaresite || state.organisation) state.clinician = null;
    let exclusivities = [['patientID', 'dateCreated', 'dateConducted', 'lastUpdated'], ['severity', 'testStatus']];
    if (batchId || state.batchId) exclusivities = [Object.values(FiltersType)];
    let f;
    const exclusive = exclusivities.find(ex => { f = ex.filter(k => Boolean(state[k])).pop(); return f; });
    if (exclusive) {
      exclusive.filter(k => k !== f).forEach(k => state[k] = null);
    }
    
    // unsets severity or testStatus if orga/hcs are unselected
    if ((('organisation' in state && state.organisation === undefined) || ('healthcaresite' in state && state.healthcaresite === undefined)) && (severity || testStatus) && currentUser.data.owners.length > 3) {
      if (severity) state.severity = null;
      if (testStatus) state.testStatus = null;
    }
    
    setLocationState({ ...state });
    return state;
  }
  const setStatus = statuses => onChange({ statuses });
  const setShowArchived = show => onChange({ showArchived: Boolean(show) });

  // This is necessary in case the selected owners are changed in the context
  if (currentUser.data.owners.length > 3 && (filters.severity || filters.testStatus) && !owner) {
    Object.assign(filters, onChange({ severity: null, testStatus: null }));
  }

  return FindStudyUI({
    ...locState,
    patientBirth: doB,
    currentUser,
    statusCounts,
    filters,
    maxNumberOfTests: 10,
    setFilters: onChange,
    trial,
    clinician: cli,
    clinicianOptions,
    device: dev,
    healthcaresite: hcs,
    loading: loadingStudies || loadingTests,
    loadingExportData,
    notify: props.notify,
    onStatusChangedHandler: setStatus,
    onShowArchivedChangedHandler: setShowArchived,
    organisation: org,
    statuses,
    organisations,
    healthcaresites,
    showArchived,
    exclusiveArchived: true,
    amountOfArchivedStudies: ARCHIVED,
    loadingArchived,
    tableConfig,
    reportType,
    loadingCounters,
    tableSettings,
    productId,
    showAdvanceFilters,
    setShowAdvanceFilters,
    batchId,
  });
};

const FindStudy = FindStudyPresenter;

FindStudy.displayName = 'FindStudy';
export default FindStudy;