import * as Pages from './ui';

import Config from './config';
import { rxExecute as execute, rxExecuteBatch as executeBatch } from 'modules/executor';
import { publish } from 'modules/router';

const getModel  = (aggrModels, aggr) => aggr.split('/').reduce((model, aggrName, idx) => idx ? model.entities[aggrName] : model[aggrName], aggrModels);
const toAction  = (aggr, action) => (args, options) => (dispatch) => import('services/server/functions/model/diagnosis/model').then(model => execute(getModel(model, aggr).actions[action].newRequest(args, args.metadata), options)(dispatch)).catch(e => console.error(e) || Promise.reject(e));
const toRequest = (aggr, action) => (args, metadata) => import('services/server/functions/model/diagnosis/model').then(model => model[aggr].actions[action].newRequest(args, metadata)).catch(e => console.error(e) || Promise.reject(e))
const actions = {
  createStudy     : (study) => toAction('Study', 'CREATE_STUDY')({...study, activationCode: { required: true }}),
  createStudyBatch: (studies, onProgress, prevResults) => {
    return (dispatch) => {
      return Promise.all(studies.map(s => toRequest('Study', 'CREATE_STUDY')({...s, activationCode: { required: true }}, { ...s.metadata })))
        .then(async reqs => {
          const fn = executeBatch(reqs, {batch: {onProgress, results: prevResults}});
          const res = await fn(dispatch);
          return res;
        });
    };
  },
  addMarker       : toAction('Study/Test/Marker', 'ADD_MARKER'),
  editMarker      : toAction('Study/Test/Marker', 'EDIT_MARKER'),
  removeMarker    : toAction('Study/Test/Marker', 'REMOVE_MARKER'),
  updateStudy     : toAction('Study', 'UPDATE_STUDY'),
  updatePatientDetails: toAction('Study', 'UPDATE_PATIENT_DETAILS'),
  editComments    : toAction('Study/Test', 'COMMENT_TEST'),
  editStudyComments: toAction('Study', 'COMMENT_STUDY'), // TODO: deprecate once fully migrated to Test entity
  cancelStudy     : toAction('Study', 'CANCEL_STUDY'),
  cancelStudyBatch: (studiesIds, onProgress) => {
    return (dispatch) => {
      return Promise.all(studiesIds.map(id => toRequest('Study', 'CANCEL_STUDY')({ id })))
        .then(async reqs => {
          const fn = executeBatch(reqs, { batch: { onProgress } });
          const res = await fn(dispatch);
          return res;
        });
    };
  },
  restoreStudy    : toAction('Study', 'RESTORE_STUDY'),
  getUsageReport  : toAction('Study', 'GET_USAGE_REPORT'),
  refreshActivationCode: toAction('Study', 'REFRESH_ACTIVATION_CODE'),
  getPatientForm  : toAction('Study', 'GET_PATIENT_PROFILE_FORM'),
  getSignalsImage: toAction('Study/Test', 'GET_SIGNALS_SCREENSHOT'),
  updateClinicianAnalysis : toAction('Study', 'EDIT_CLINICIAN_ANALYSIS'),
  archive         : toAction('Study', 'ARCHIVE_STUDY'),
  unarchive       : toAction('Study', 'UNARCHIVE_STUDY'),
  generateStudyPdfReport: toAction('Study', 'EXPORT_SLEEP_STUDY'),
  applySignature: toAction('Study', 'APPLY_SIGNATURE'),
  deleteSignature: toAction('Study', 'DELETE_SIGNATURE'),
  markAsReviewed: toAction('Study', 'MARK_AS_REVIEWED'),
  markAsFinished: toAction('Study', 'MARK_AS_FINISHED'),
};

export const StudyLanding     = publish(Config.routes.StudyLanding, actions)(Pages.StudyLanding);
export const FindStudy        = publish(Config.routes.FindStudy, actions)(Pages.FindStudy);
export const FindStudyEvents  = publish(Config.routes.FindStudyEvents, actions)(Pages.FindStudyEvents);
export const CreateStudy      = publish(Config.routes.CreateStudy, actions)(Pages.CreateStudy);
export const CreateStudyBatch = publish(Config.routes.CreateStudyBatch, actions)(Pages.CreateStudyBatch, { page: "CreateStudyBatch" });
export const EditStudy        = publish(Config.routes.EditStudy, actions)(Pages.EditStudy);
//export const UsageReport    = publish(Config.routes.UsageReport, actions)(Pages.UsageReport);
export const ViewStudy      = publish(Config.routes.ViewStudy, actions)(Pages.ViewStudy);
export const ViewTest       = publish(Config.routes.ViewTest, actions)(Pages.ViewTest);
//export const EditComments   = publish(Config.routes.EditComments, actions)(Pages.EditComments);
export const UploadTestFile = Pages.UploadTestFile && publish(Config.routes.UploadTestFile, actions)(Pages.UploadTestFile);